@import "~react-image-gallery/styles/css/image-gallery.css";

h1 {
  font-size: 32px !important;
}

.light-pink-bg {
  background-color: #FFBCD0;
}

.super-light-pink-bg {
  background-color: #F8F3F5;
}

.pink-bg {
  background-color: #E95397;
}

.pink2-bg {
  background-color: #FF6A97;
}

.pink-text {
  color: #E95397;
}

.light-gray-text {
  color: #B5ADAD;
}

.gray-text {
  color: #808080;
}

.green-bg {
  background-color: #00ABAE;
}

.green-text {
  color: #00ABAE;
}

.purple-bg {
  background-color: #734A9E;
}

.purple-text {
  color: #734A9E;
}

.header,
.header-blog {
  box-shadow: 7px 20px 13px -16px #ddd;
}

.header,
.header-blog {
  background-color: #fff;
  position: sticky;
  margin: 0;
  z-index: 2;
  top: 0;
  padding-bottom: 10px;
}

.header .menu ul,
.header-blog ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header .menu ul li {
  float: left;
  font-size: 1.125rem;
  color: #4B4F58;
  cursor: pointer;
}

.header-blog span {
  font-size: 20px;
  color: #4B4F58;
  cursor: pointer;
  font-weight: 600;
}

.blog-border-right {
  border-right: 1px solid #ddd;
}

.header .menu ul li.active,
.header-blog span.active {
  color: #FF6A97;
}

.header .menu ul li:hover {
  color: #FF6A97;
}

.header .button {
  background-color: #FF6A97;
  border-radius: 5px;
  color: #fff;
  padding: 15px 40px;
  transition: 0.5s;
}

.header .button:hover {
  background-color: #FF96B0;
}

.header-line {
  height: 2px;
  background: linear-gradient(to right, #FF6A97, #FF96B0);
  z-index: 2;
}

.mobile-header {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;
}

.mobile-header .burger {
  font-size: 28px;
  color: #FF6A97;
  font-weight: 600;
  border: 1px solid #FF6A97;
  width: max-content;
  padding-top: 10px;
}

.mobile-header ul {
  list-style-type: none;
}

.mobile-header ul li {
  color: #4B4F58;
  cursor: pointer;
}

.mobile-header ul li:hover {
  color: #FF6A97;
}

.w-1200 {
  max-width: 1200px;
  padding: 0 20px;
}

.about-us .box .box-container,
.foundation-team img {
  box-shadow: 5px 5px 10px #333;
  border-radius: 10px;
}

.shadow {
  box-shadow: 5px 5px 10px #333 !important;
}

.about-us .box .icon {
  font-size: 50px;
}

.gray-text {
  color: #645659;
}

.page-title {
  font-size: 50px;
}

.page-subtitle {
  font-size: 35px;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.avatar {
  height: 150px;
  width: 150px;
}

.avatar img {
  border-radius: 50%;
  border: 5px solid #FF96B0;
}

.partner-container {
  background-color: #F8F3F5;
}

.project-card-container {
  border-radius: 20px;
}

.project-card-container .card-img-top {
  border-radius: 20px 20px 0 0 !important;
}

.project-card {
  background-color: #F8F3F5;
  border: 3px solid #F1D6E5;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
  color: #000;
  min-height: 200px;
  border-radius: 15px;
}

.project-card:hover {
  background-color: #F1D6E5;
}

.project-card i {
  font-size: 32px;
}

.footer {
  background-color: #FFBCD0;
}

.project-category-list-container,
.project-details,
.individual-help {
  padding-top: 100px;
}

.project-category-list-container .card {
  border: none;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15);
}

.project-category-list-container .card {
  color: #000;
}

.trim-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.help i {
  font-size: 32px;
}

.request-button {
  transition: background 0.5s;
  width: fit-content;
  border-radius: 10px;
  font-size: 16px;
  padding: 20px 30px;
  font-weight: 600;
}

.request-button:hover {
  background-color: #FF96B0;
}

.contact-box {
  background-color: #F8F3F5;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15);
  padding: 20px 0;
  border-radius: 10px;
}

.contact-box i {
  font-size: 24px;
  color: #FF6A97
}

.donate-section {
  position: relative;
}

.donate-section-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.donate-section-content .title {
  color: #fff;
  font-size: 80px;
  font-weight: 600;
  font-family: 'Roboto Condensed', sans-serif;
}

.donate-section-content .subtitle {
  font-weight: 600;
  font-family: 'Roboto Condensed', sans-serif;
}

.donate-section-content .donate,
.more-about-btn,
.home-founders-word .button,
.slogan-home .button,
.donate-mobile-button,
.home-donate-btn {
  width: fit-content;
  background-color: #FF6A97;
  border-radius: 5px;
  color: #fff;
  padding: 15px 40px;
  transition: 0.5s;
}

.donate-section-content .donate:hover,
.more-about-btn:hover,
.home-founders-word .button:hover,
.slogan-home .button:hover,
.donate-mobile-button,
.home-donate-btn:hover {
  background-color: #FF96B0;
  color: white;
}

.header-img,
.header-img-blog {
  position: relative;
}

.header-img .title {
  position: absolute;
  bottom: 25%;
  left: 10%;
  color: #fff;
  font-size: 80px;
  font-weight: 600;
  font-family: 'Roboto Condensed', sans-serif;
}

.header-img .subtitle {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.overlay {
  position: absolute;
  background-color: #000000;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  top: 0;
}

.overlay2 {
  position: absolute;
  height: 50% !important;
  width: 100%;
  height: 100%;
  bottom: -1px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  border: none;
}

.gradient {
  position: absolute;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  /* opacity: 0.4; */
  width: 100%;
  height: 100%;
  top: 50%;
}

.custom-carousel {
  position: relative;
}


.custom-carousel .legend {
  background: none !important;
  color: #fff !important;
  opacity: 1 !important;
}

.custom-carousel .title {
  font-size: 40px !important;
  font-weight: 700 !important;
}

.custom-carousel .subtitle {
  font-size: 20px !important;
}

.custom-carousel .btn {
  color: #fff !important;
  font-size: 20px !important;
  border: 2px solid #fff !important;
}

.home-goals {
  margin-top: 60px;
  margin-bottom: 60px;
}

.home-card {
  border: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.home-card .card-title {
  font-size: 16px;
  font-weight: 600;
}

.home-card .card-text {
  font-size: 14px;
}

.home-founders-word img {
  max-height: 500px;
  /* width: 100%; */
  border-radius: 10%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.home-founders-word .content .title {
  font-size: 50px;
  font-weight: 600;
}

.home-founders-word .content .text {
  font-size: 18px;
}

.slider-image {
  border-radius: 20px !important;
}

.account-number {
  font-size: 30px;
}

.counter-icon {
  font-size: 40px;
}

.counter-number {
  font-size: 50px;
  font-weight: 600;
}

.counter-text {
  font-size: 20px;
}

.donate-container .box-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.slider-chevron {
  height: 25px;
  width: 25px;
  border-top: solid 3px #000;
  border-right: solid 3px #000;
  border-bottom: none !important;
  transform: rotate(45deg);
  position: relative;
  right: 3px;
}

.slider-container button {
  background: none !important;
}

.slogan-home {
  position: absolute;
  left: 50%;
  bottom: -50%;
  transform: translate(-50%, -50%);
  color: #fff;
  opacity: 0;
  transition: 1s;
}

.slogan-home.fade-up {
  left: 50%;
  bottom: 20%;
  opacity: 1;
}

.home-donate-btn {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.slogan-home-container {
  position: relative;
}

.slogan-home .slogan-title {
  font-size: 85px;
  font-weight: 700;
  color: #fff;
  position: relative;
  bottom: -50%;
}

.card-text p:not(:first-child) {
  display: none;
}

.card-text p:first-child {
  margin-bottom: 0;
}

.aword-container {
  overflow: hidden;
}

.aword-container .left {
  position: relative;
  left: -100%;
  transition: 1.5s;
  opacity: 0;
}

.aword-container .left.show-in {
  position: relative;
  left: 0;
  opacity: 1;
}

.aword-container .right {
  position: relative;
  right: -100%;
  transition: 1.5s;
  opacity: 0;
}

.aword-container .right.show-in {
  position: relative;
  right: 0;
  opacity: 1;
}

.aword-container img {
  border-radius: 10px;
  overflow: hidden;
}

.gallery_container {
  position: relative;
}

.image-gallery-slide-wrapper {
  position: relative;
}

.gallery-close-button {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

.gallery-close-button .line1,
.gallery-close-button .line2 {
  background-color: #fff;
  width: 25px;
  height: 3px;
  margin: 6px 0;
}

.gallery-close-button .line1 {
  transform: translate(0, -2px) rotate(-45deg);
}

.gallery-close-button .line2 {
  transform: translate(0, -11px) rotate(45deg);
}

.image-gallery {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 50%;
  top: 50%;
  z-index: 4;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  display: none;
  z-index: 3;
}

.image-gallery-image {
  height: 80vh;
  padding: 20px 0px;
}

.other-articles {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px 20px;
}

.other-articles-list {
  max-height: 400px;
  overflow-y: scroll;
}

.sidebar_box::-webkit-scrollbar,
.other-articles-list::-webkit-scrollbar {
  width: 10px;
}

.sidebar_box::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 10px;
}

.other-articles-list::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-bottom: 10px;
}

.sidebar_box::-webkit-scrollbar-thumb,
.other-articles-list::-webkit-scrollbar-thumb {
  background: #E4E6E9;
  border-radius: 10px;
}

.sidebar_box::-webkit-scrollbar-thumb:hover,
.other-articles-list::-webkit-scrollbar-thumb:hover {
  background: #D7D8DB;
}

.image-gallery-content {
  top: 50% !important;
  transform: translateY(-50%);
}

.image-gallery-slide-wrapper button:hover {
  color: #E95397;
}

.showGallery {
  display: block;
}

.finance.request-button {
  background-color: #B2C8D2 !important;
  padding: 30px 20px;
  font-size: 20px;
}

.blog-container {
  background-color: #fff;
}

.first-news-container {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}

.home-news .first-news-container .description {
  padding: 10px;
  position: absolute;
  bottom: 0;
  color: #fff;
  height: 50%;
  background-image: linear-gradient(180deg, rgba(243, 59, 151, 0), rgba(243, 59, 151, 1));
  font-size: 24px;
}

.first-news-container .description .title {
  margin-bottom: 10px;
}

.first-news-container .description .tag {
  margin-bottom: 15px;
}

.first-news-container .description .date {
  font-size: 12px;
}

.home-other-news .news-container {
  position: relative;
}

.home-other-news .cover-image img {
  height: 125px;
  width: 175px;
  border-radius: 20px;
}

.home-other-news .news-container .info {
  margin-left: 10px;
  height: 100%;
}

.home-other-news .news-container .info .title {
  color: #FF439F;
  font-size: 16px;
  font-weight: 600;
}

.home-other-news .news-container .info .date {
  color: #FF439F;
  font-size: 14px;
  line-height: 141.5%;
}

.home-other-news .news-container:not(:first-child) {
  margin-top: 5px;
}

.video-box {
  background: #1A191B;
}

.video-box video {
  height: 500px;
  width: 100% !important;
}

.section-line {
  height: 1px;
}

.section .button {
  color: #fff;
  border-radius: 10px;
}

.section .title {
  font-size: 20px;
  font-weight: 600;
}

.our-stories .project-card-container,
.interviews .project-card-container {
  box-shadow: 5px 5px 5px #ddd;
}

.blog-interviews .project-card-container {
  box-shadow: 5px 5px 10px #00ABAE;
  border: none !important;
}

.blog-our-stories .project-card-container {
  box-shadow: 5px 5px 10px #734A9E;
  border: none;
}

.interview-page,
.our-stories-page {
  background-color: #fff;
}

.others-about-us-card-container .card {
  border-radius: 20px;
}

.others-about-us-card-container .main-img{
  border-radius: 40px;
  padding: 20px;
}

.others-about-us-card-container .news-button {
  background-color: #E95397;
  color: #fff;
  border-radius: 5px;
}

.blog-other-news {
  font-weight: 600;
  color: #4B4F58;
}

.blog-other-news .border-top {
  border-top: 1px solid #ddd;
}

.blog-other-news:hover {
  color: #E95397
}

.counter2 {
  background-image: url('/src/counter.webp');
  border-radius: 50px 0 50px 0;
  padding: 30px 0;
}

.counter2 .counter-icon,
.counter2 .counter-number,
.counter2 .counter-text {
  color: #fff;
  font-weight: 600;
}

.header-img-blog .title {
  position: absolute;
  bottom: 5%;
  left: 5%;
  color: #fff;
  font-size: 80px;
  font-weight: 600;
  font-family: 'Roboto Condensed', sans-serif;
}

.follow-us {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.follow-us i {
  font-size: 25px;
}

.home-founders-word img {
  width: auto;
}

@media (max-width: 768px) {
  .home-founders-word img {
    width: 100%;
  }

  .header-blog span {
    font-size: 16px;
  }

  .video-box video {
    height: auto;
  }
  
  .header-img .title {
    position: absolute;
    bottom: 0%;
    left: 5%;
    color: #fff;
    font-size: 50px;
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
  }

  .header-img .subtitle {
    font-size: 12px;
  }

  .project-category-list-container,
  .project-details,
  .individual-help {
    padding-top: 0px;
  }

  .donate-section-content .title {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
  }

  .donate-section-content .subtitle {
    font-size: 12px;
  }

  .slogan-home {
    position: absolute;
    color: #fff;
  }

  .slogan-home .slogan-title {
    padding-top: 30px;
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .slogan-home .button {
    padding: 10px;
  }

  .home-goals {
    font-size: 14px;
  }

  .custom-carousel img {
    height: 200px;
  }

  .home-founders-word .content .title {
    font-size: 20px;
    font-weight: 600;
  }

  .home-founders-word .content .text {
    font-size: 12px;
  }

  .home-founders-word .content .button {
    font-size: 12px;
    padding: 10px 15px;
  }

  .moto {
    font-size: 18px;
  }

  .home-description {
    font-size: 16px;
  }

  .custom-carousel .title {
    font-size: 18px !important;
    font-weight: 700 !important;
    padding-top: 20px !important;
  }

  .custom-carousel .subtitle {
    font-size: 12px !important;
  }

  .custom-carousel .btn {
    color: #fff !important;
    font-size: 14px !important;
    border: 2px solid #fff !important;
  }

  .counter-number {
    font-size: 30px;
    font-weight: 600;
  }

  .counter-text {
    font-size: 16px;
  }

  .slider-chevron {
    height: 18px;
    width: 18px;
  }

  .donate-section-content .title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
  }

  .donate-section-content .donate {
    padding: 10px 15px;
  }

  .donate-section-content .subtitle {
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
  }

  .donate-section-content .subtitle {
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
  }

  .header-img .title {
    position: absolute;
    bottom: 20%;
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
  }

  .header-img-blog .title {
    position: absolute;
    bottom: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
  }

  .partner-container h3 {
    font-size: 16px;
  }

  .become-member h3 {
    font-size: 26px;
  }

  .account-number {
    font-size: 24px;
  }

  .slogan-home.fade-up {
    left: 50%;
    bottom: 25%;
    opacity: 1;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .slogan-home {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  .slogan-home .slogan-title {
    font-size: 50px;
    font-weight: 700;
    color: #fff;
  }

  .home-founders-word img {
    width: 100%;
  }
}